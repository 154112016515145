<template>
  <a-card>
    <div slot="title">基本信息</div>
    <basis-form ref="basis" />
    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button :loading="loading" type="primary" shape="round" style="width:200px" @click="onSubmit">
          保存
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import events from '@/components/MultiTab/events'
import * as serviceManagerApi from '@/api/serviceManager'
import BasisForm from './components/BasisForm'
export default {
  name: 'ServiceManagerCreate',
  components: {
    BasisForm
  },
  data() {
    return {}
  },
  methods: {
    onSubmit() {
      const {
        $refs: { basis },
        $notification,
        close
      } = this

      basis.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            serviceManagerApi
              .create(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                close()
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      events.$emit('close')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped></style>
